// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React from "react";
import { useState } from "react";

const ME = loader("../graphql/me.graphql");

export default function useAuth() {
  const [user, setUser] = React.useState();

  const [me] = useLazyQuery(ME, {
    onCompleted: (data) => {
      setUser(data.me);
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    me();
  }, [me]);

  const getToken = () => {
    const tokenString = window.localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.accessToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if (userToken) {
      window.localStorage.setItem("token", JSON.stringify(userToken));
      setToken(userToken.accessToken);
      me();
    }
  };

  const removeToken = () => {
    window.localStorage.removeItem("token");
    setToken(undefined);
    setUser(undefined);
  };

  return {
    setToken: saveToken,
    removeToken,
    token,
    user,
  };
}
