import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
//import { WebSocketLink } from "@apollo/client/link/ws";
//import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

const getToken = () => {
  const tokenString = window.localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.accessToken;
};

/*
const wsLink = new WebSocketLink({
  uri: window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SUBSCRIPTION,
  options: {
    reconnect: true,
    connectionParams: () => {
      return {
        isWebSocket: true,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      };
    },
  },
});
*/

const httpLink = new HttpLink({
  uri: window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_SERVER,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

/*
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);
*/

const graphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Box: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: ["mac"],
      },
    },
  }),
});

export default graphqlClient;
