import React from "react";
import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from "@mui/material";
import { useMutation } from "@apollo/client";

const columns = [
  { field: "username", width: 150 },
  { field: "roles", width: 150 },
  { field: "created", width: 200, type: "dateTime", valueGetter: (params) => new Date(params.row?.created) },
  { field: "updated", width: 200, type: "dateTime", valueGetter: (params) => new Date(params.row?.updated) },
];

const QUERY = loader("../../graphql/users.graphql");
const DELETE = loader("../../graphql/deleteOneUser.graphql");

export default function UserList() {
  const context = useDatagrid({ id: "UserList", columns });
  let navigate = useNavigate();

  const [deleteOneUser] = useMutation(DELETE);

  const selectionModel = context.selectionModel;

  const handleDelete = () => {
    deleteOneUser({
      variables: {
        input: {
          id: selectionModel[0],
        },
      },
    });
  };

  return (
    <div style={{ display: "flex", height: "85vh" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid columns={columns} context={context} query={QUERY} />
        {
          <ButtonGroup color="primary">
            <Button onClick={() => navigate(`/users/create`)}>
              Create
            </Button>
            <Button
              disabled={selectionModel.length === 0}
              onClick={() => navigate(`/users/${selectionModel[0]}`)}
            >
              Edit
            </Button>
            <Button
              disabled={selectionModel.length === 0}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      </div>
    </div>
  );
}
