import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Box, Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Stack, Switch, TextField } from "@mui/material";
import { loader } from "graphql.macro";
import { useNavigate, useParams } from "react-router-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

const QUERY = loader("../../graphql/script.graphql");
const UPDATE = loader("../../graphql/updateOneScript.graphql");
const CREATE = loader("../../graphql/createOneScript.graphql");

const QUERY_TARGET = loader("../../graphql/productTargets.graphql");

export default function ScriptEdit() {

  let { id } = useParams();
  let navigate = useNavigate();

  const [script, setScript] = React.useState({ name: "", content: "", description: "", targets: { nodes: [] } });
  const [targets, setTargets] = React.useState([]);

  const { loading, error } = useQuery(QUERY, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      setScript(data.script || { name: "", content: "", description: "", targets: { nodes: [] } });
    },
    skip: id === "create",
  });

  useQuery(QUERY_TARGET, {
    onCompleted: (data) => {
      setTargets(data.productTargets.nodes);
      console.log(targets);
    },
  });

  const [updateOneScript] = useMutation(UPDATE, {
    onCompleted: () => navigate(-1),
  });

  const [createOneScript] = useMutation(CREATE, {
    onCompleted: () => navigate(-1),
  });

  if (error) {
    console.error(error);
    return <p>Error :(</p>;
  }
  if (loading || !script) {
    return <p>Loading...</p>;
  }

  function onContentChange(content) {
    setScript({ ...script, content });
  }

  const onNameChange = (event) => {
    setScript({ ...script, name: event.target.value });
  };

  const onDescriptionChange = (event) => {
    setScript({ ...script, description: event.target.value });
  };

  const handleSave = () => {
    if (id === "create") {
      createOneScript({
        variables: {
          input: {
            script: {
              name: script.name,
              content: script.content,
              description: script.description,
              targets: script.targets.nodes.map((target) => { return { id: target.id } }),
            }
          },
        },
      });
    } else {
      updateOneScript({
        variables: {
          input: {
            id,
            update: {
              name: script.name,
              content: script.content,
              description: script.description,
              targets: script.targets.nodes.map((target) => { return { id: target.id } }),
            },
          },
        },
      });
    }
  };


  const onTargetChange = (event) => {
    const targets = script.targets.nodes ? [...script.targets.nodes] : [];
    const changedId = parseInt(event.target.id)
    if (event.target.checked) {
      if (targets.findIndex((target) => target.id === changedId) === -1) {
        targets.push({ id: changedId });
      }
    } else {
      let index = targets.findIndex((target) => target.id === changedId)
      if (index !== -1) {
        targets.splice(index, 1);
      }
    }
    setScript({ ...script, targets: { nodes: targets } });
  };

  return (
    <Box m={1} width="90%" height="80%">
      <Stack direction="row" spacing={2} m={2}>
        <Box sx={{ display: 'flex' }}>
          <FormControl variant="standard">
            <FormLabel variant="standard">
              Target
            </FormLabel>
            <FormGroup>
              <Stack direction="row">
                {
                  targets.map((target) => (
                    <FormControlLabel
                      control={
                        <Switch checked={script?.targets?.nodes?.find((scriptTarget) => target.name === scriptTarget.name)} onChange={onTargetChange} id={target?.id?.toString()} />
                      }
                      label={target.name}
                      key={target.id}
                    />
                  ))
                }
              </Stack>
            </FormGroup>
          </FormControl>
        </Box>
        <Box minWidth={300}>
          <TextField
            label="Name"
            value={script.name}
            onChange={onNameChange}
            fullWidth={true}
            variant="standard"
          />
        </Box>
        <TextField
          label="Description"
          value={script.description}
          onChange={onDescriptionChange}
          fullWidth={true}
          variant="standard"
        />
      </Stack>
      <AceEditor
        mode="javascript"
        theme="monokai"
        onChange={onContentChange}
        editorProps={{ $blockScrolling: true }}
        setOptions={{ useWorker: false }}
        value={script.content}
        width="100%"
        height="100%"
      />
      <ButtonGroup color="primary">
        <Button onClick={() => handleSave()}>Enregistrer</Button>
        <Button onClick={() => navigate(-1)}>Annuler</Button>
      </ButtonGroup>
    </Box>
  );
}
