import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Divider,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import ComputerIcon from "@mui/icons-material/Computer";
import NotesIcon from "@mui/icons-material/Notes";
import CodeIcon from "@mui/icons-material/Code";
import DvrIcon from "@mui/icons-material/Dvr";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import PeopleIcon from "@mui/icons-material/People";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useLocation } from "react-router-dom";
import { DDS_COLOR, MELODJY_COLOR } from "../../constants/colors";
import Admin from "../Roles/Admin";
import Support from "../Roles/Support";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MyDrawer({ user }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  let location = useLocation();

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <Toolbar />
      <DrawerHeader></DrawerHeader>
      <List>
        <ListItem>
          <ListItemButton onClick={() => handleDrawerOpen()}>
            <ListItemIcon>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
            <ListItemText
              primary={drawerOpen ? "Close" : "Open"}
              sx={{ color: "text.primary" }}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem component={RouterLink} to="/dashboard">
          <ListItemButton selected={location.pathname === "/dashboard"}>
            <ListItemIcon>
              <Tooltip title="Dashboard">
                <DashboardIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{ color: "text.primary" }}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider color={DDS_COLOR} />
        <ListItem component={RouterLink} to="/boxes">
          <ListItemButton selected={location.pathname === "/boxes"}>
            <ListItemIcon>
              <Tooltip title="Boxes">
                <ComputerIcon style={{ color: DDS_COLOR }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary="Boxes"
              sx={{ color: "text.primary" }}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem component={RouterLink} to="/screenshots">
          <ListItemButton selected={location.pathname === "/screenshots"}>
            <ListItemIcon>
              <Tooltip title="Screenshots">
                <WallpaperIcon style={{ color: DDS_COLOR }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              sx={{ color: "text.primary" }}
              primary="Screenshots"
            />
          </ListItemButton>
        </ListItem>
        <Support user={user}>
          <ListItem component={RouterLink} to="/consoles/dds">
            <ListItemButton selected={location.pathname === "/consoles/dds"}>
              <ListItemIcon>
                <Tooltip title="Consoles">
                  <DvrIcon style={{ color: DDS_COLOR }} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText sx={{ color: "text.primary" }} primary="Consoles" />
            </ListItemButton>
          </ListItem>
        </Support>
        <Divider color={DDS_COLOR} />
        <Divider color={MELODJY_COLOR} />
        <ListItem component={RouterLink} to="/players">
          <ListItemButton selected={location.pathname === "/players"}>
            <ListItemIcon>
              <Tooltip title="Players">
                <VolumeUpIcon style={{ color: MELODJY_COLOR }} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary="Players"
              sx={{ color: "text.primary" }}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        <Support user={user}>
          <ListItem component={RouterLink} to="/consoles/melodjy">
            <ListItemButton
              selected={location.pathname === "/consoles/melodjy"}
            >
              <ListItemIcon>
                <Tooltip title="Consoles">
                  <DvrIcon style={{ color: MELODJY_COLOR }} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText sx={{ color: "text.primary" }} primary="Consoles" />
            </ListItemButton>
          </ListItem>
        </Support>
        <Divider color={MELODJY_COLOR} />
        <Admin user={user}>
          <ListItem component={RouterLink} to="/scripts">
            <ListItemButton selected={location.pathname === "/scripts"}>
              <ListItemIcon>
                <Tooltip title="Scripts">
                  <CodeIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText sx={{ color: "text.primary" }} primary="Scripts" />
            </ListItemButton>
          </ListItem>
        </Admin>
        <Divider />
        <ListItem component={RouterLink} to="/logs">
          <ListItemButton selected={location.pathname === "/logs"}>
            <ListItemIcon>
              <Tooltip title="Logs">
                <NotesIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText sx={{ color: "text.primary" }} primary="Logs" />
          </ListItemButton>
        </ListItem>
        <Admin user={user}>
          <ListItem component={RouterLink} to="/users">
            <ListItemButton selected={location.pathname === "/users"}>
              <ListItemIcon>
                <Tooltip title="Users">
                  <PeopleIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText sx={{ color: "text.primary" }} primary="Users" />
            </ListItemButton>
          </ListItem>
        </Admin>
        <ListItem component={RouterLink} to="/settings">
          <ListItemButton selected={location.pathname === "/settings"}>
            <ListItemIcon>
              <Tooltip title="Settings">
                <SettingsIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText sx={{ color: "text.primary" }} primary="Settings" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
