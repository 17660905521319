/*
    Check if conf.js is uniboservices.com
*/

import { Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import useFilterUrl from "../../../hooks/useFilterUrl";
import { generateFilter } from "../../../hooks/useDatagrid";
import { columns } from "../../BoxList";
import { Link as RouterLink } from "react-router-dom";
import Progress from "../../Progress";
import GraphqlError from "../../GraphqlError";

const PLAYERS = loader("../../../graphql/playersCount.graphql");

export default function NoSoundMelodjyDashboardPlugin() {
  const { generateFilterModel, compress, filterCompressed } = useFilterUrl();

  const filterModel = generateFilterModel(
    [
      ["supervision_soundRunning", "is", false],
      ["ipPublic", "!=", window.__RUNTIME_CONFIG__.REACT_APP_IP_UNIGUEST],
    ],
    "and",
  );

  if (!filterCompressed) {
    compress(filterModel);
  }

  const filter = generateFilter(filterModel, columns);

  const { loading, error, data } = useQuery(PLAYERS, {
    variables: {
      filter,
      paging: { limit: 10000, offset: 0 },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 300000,
  });

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <GraphqlError error={error} />;
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="MELODJY NO SOUND"
        sx={{ textAlign: "center" }}
      ></CardHeader>
      <CardContent>
        <Typography variant="h3" textAlign="center">
          <Link
            component={RouterLink}
            to={`/players?filter=${filterCompressed}`}
          >
            {data.players.totalCount}
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}
