import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { TokenContext } from "../../contexts/tokenContext";

export default function MyAppBar() {
  const clearLocalStorage = () => {
    const token = window.localStorage.getItem("token"); // save and restore auth token to avoid disconnect
    window.localStorage.clear();
    window.localStorage.setItem("token", token);
    window.location.reload();
  };

  return (
    <TokenContext.Consumer>
      {(tokenContext) => (
        <AppBar position="fixed" sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}>
          <Toolbar>
            <Box flexGrow={1}>
              <Typography variant="h6" noWrap component="span">
                PCI UI
              </Typography>
              <Typography variant="body1" component="span">
                &nbsp;v{process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
            <Button variant="outlined" onClick={() => clearLocalStorage()}>
              Clear local storage
            </Button>
            <Button variant="outlined" onClick={tokenContext.removeToken}>
              Déconnexion
            </Button>
          </Toolbar>
        </AppBar>
      )}
    </TokenContext.Consumer>
  );
}
