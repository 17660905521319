import { TextField } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import React from "react";

function StringInputValue(props) {
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <TextField
      name="custom-string-filter-operator"
      placeholder="Filter value"
      label="Value"
      variant="standard"
      value={item.value}
      onChange={handleFilterChange}
    />
  );
}

const stringOperators = [
  ...getGridStringOperators(),
  {
    label: "not contains",
    value: "not contains",
    InputComponent: StringInputValue,
  },
  {
    label: "in",
    value: "in",
    InputComponent: StringInputValue,
  },
  {
    label: "not equals",
    value: "!=",
    InputComponent: StringInputValue,
  },
];

const stringColumnType = {
  extendType: "string",
  filterOperators: stringOperators,
};

export default stringColumnType;
