import React from "react";
import {Box} from "@mui/material";
import { TokenContext } from "../../contexts/tokenContext";

export default function GraphqlError({ error }) {
  const tokenContext = React.useContext(TokenContext);

  console.error(error);
  if (error.message === "Unauthorized") {
    tokenContext.removeToken();
  }
  return <Box color="error.main">{error.message}</Box>;
}
