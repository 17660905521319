import { Box, Grid } from "@mui/material";
import TotalDashboardPlugin from "./plugins/total";
import DisconnectedDashboardPlugin from "./plugins/disconnected";
import ConfDashboardPlugin from "./plugins/conf";
import SupervisionDashboardPlugin from "./plugins/supervision";
import AïeDashboardPlugin from "./plugins/aïe";
import BlackDashboardPlugin from "./plugins/black";
import GitPlayerVersionDashboardPlugin from "./plugins/gitPlayerVersion";
import PciNestClientVersionDashboardPlugin from "./plugins/pciNestClientVersion";
import SecurePasswordnDashboardPlugin from "./plugins/securePassword";
import OcsDashboardPlugin from "./plugins/ocs";
import GitDashboardPlugin from "./plugins/git";
import DisconnectedMelodjyDashboardPlugin from "./plugins/melodjy_disconnected";
import NoSoundMelodjyDashboardPlugin from "./plugins/melodjy_nosound";
import PlayerVersionMelodjyDashboardPlugin from "./plugins/melodjy_playerVersion";
import { DDS_COLOR, MELODJY_COLOR } from "../../constants/colors";
import React from "react";
import LowdbMelodjyDashboardPlugin from "./plugins/melodjy_lowdb";
import ConfigDatabaseEmptyMelodjyDashboardPlugin from "./plugins/melodjy_configDatabaseEmpty";

export default function Dashboard() {
  return (
    <React.Fragment>
      <Box bgcolor={DDS_COLOR} m={1} p={1}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TotalDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <DisconnectedDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <AïeDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <BlackDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <GitPlayerVersionDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <PciNestClientVersionDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <ConfDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <SupervisionDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <GitDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <OcsDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <SecurePasswordnDashboardPlugin />
          </Grid>
        </Grid>
      </Box>

      <Box bgcolor={MELODJY_COLOR} m={1} p={1}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <DisconnectedMelodjyDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <NoSoundMelodjyDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <PlayerVersionMelodjyDashboardPlugin />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <ConfigDatabaseEmptyMelodjyDashboardPlugin />
          </Grid>
          <Grid item xs={3}>
            <LowdbMelodjyDashboardPlugin />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment >
  );
}
