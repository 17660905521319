import React from "react";
import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";
import { Button, ButtonGroup, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Papa from "papaparse";

const columns = [
  { field: "id", width: 60 },
  {
    field: "targets.name", width: 250,
    valueGetter: (params) => params.row.targets,
    renderCell: (params) => (
      params.value.nodes.map((target) => (
        <Chip label={target.name}></Chip>
      ))
    ),
  },
  { field: "name", width: 250 },
  { field: "decription", flex: 1 },
];

const QUERY = loader("../../graphql/scripts.graphql");
const DELETE = loader("../../graphql/deleteOneScript.graphql");
const CREATE = loader("../../graphql/createOneScript.graphql");

export default function ScriptList() {
  const context = useDatagrid({ id: "ScriptList", columns });
  let navigate = useNavigate();

  const [deleteOneScript] = useMutation(DELETE);
  const [createOneScript] = useMutation(CREATE);

  const selectionModel = context.selectionModel;

  const handleDelete = () => {
    deleteOneScript({
      variables: {
        input: {
          id: selectionModel[0],
        },
      },
    });
  };

  const handleCsvImport = ({ target }) => {
    Papa.parse(target.files[0], {
      complete: function (results) {
        if (results.errors.length === 0) {
          results.data.forEach((row, index) => {
            if (index !== 0) {
              const script = {
                name: row[1],
                content: row[4],
              };
              createOneScript({
                variables: {
                  input: {
                    script,
                  },
                },
              });
            }
          });
        } else {
          console.error(results.errors);
        }
      },
    });
  };

  return (
    <div style={{ display: "flex", height: "85vh" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          columns={columns}
          context={context}
          query={QUERY}
          quickSearchColumn="name"
        />
        <ButtonGroup color="primary">
          <Button onClick={() => navigate(`/scripts/create`)}>
            Create
          </Button>
          <Button
            disabled={selectionModel.length === 0}
            onClick={() => navigate(`/scripts/${selectionModel[0]}`)}
          >
            Edit
          </Button>
          <Button
            disabled={selectionModel.length === 0}
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
          <label htmlFor="contained-button-file">
            <input
              accept=".csv"
              id="contained-button-file"
              type="file"
              hidden
              onChange={handleCsvImport}
            />
            <Button variant="outlined" component="span">
              Import
            </Button>
          </label>
        </ButtonGroup>
      </div>
    </div>
  );
}
