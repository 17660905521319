import { useEffect, useCallback } from "react";
import { io } from "socket.io-client";
import useState from "react-usestateref";
import { useSnackbar } from "notistack";

export default function useConsole(token, createMainSocket = true, namespace = "/") {
  const { enqueueSnackbar } = useSnackbar();
  const [mainSocket, setMainSocket] = useState(); // master websocket
  const [sockets, setSockets, socketsRef] = useState([]);
  const [logs, setLogs, logsRef] = useState({});

  const initSocket = useCallback(
    (mac) => {
      const socket = io(window.__RUNTIME_CONFIG__.REACT_APP_WEBSOCKET_SERVER + namespace, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
        auth: { mac, console: true },
      });
      if (mac) {
        socket.mac = mac;
      }

      socket.connect();

      socket.on("screenshotUpdated", function (mac) {
        enqueueSnackbar(`Screenshot updated ${mac}`);
      });

      return socket;
    },
    [enqueueSnackbar, token, namespace]
  );

  useEffect(() => {
    if (createMainSocket) {
      setMainSocket(initSocket());
    }
    // eslint-disable-next-line
  }, [initSocket]);

  const addConsole = (mac) => {
    const socket = sockets.find((socket) => socket.mac === mac); // add only once
    if (!socket) {
      const socket = initSocket(mac);

      socket.on("error", (data) => {
        console.error(data);
      });

      socket.on("console.log", (data) => {
        let _logs = { ...logsRef.current };
        _logs[mac].unshift({
          type: "log",
          arguments: data.arguments,
          date: new Date(),
        });
        setLogs({ ..._logs });
      });

      socket.on("console.error", (data) => {
        let _logs = { ...logsRef.current };
        _logs[mac].unshift({
          type: "error",
          arguments: data.arguments,
          date: new Date(),
        });
        setLogs({ ..._logs });
      });

      setSockets([...socketsRef.current, socket]);

      // Init logs
      logs[mac] = [];
      setLogs(logs);
    }
  };

  const removeConsole = (mac) => {
    const socket = sockets.find((socket) => socket.mac === mac);
    if (socket) {
      socket.close();
      setSockets(sockets.filter((socket) => socket.mac !== mac));
      clearLogs(mac);
    }
  };

  const removeAllConsoles = () => {
    sockets.forEach((socket) => {
      clearLogs(socket.mac);
      socket.close();
    });
    setSockets([]);
  };

  const execScript = (socket, scriptId) => {
    socket.emit("command", { to: [socket.mac], scriptId });
  };

  const mainExecScript = (mac, scriptId) => {
    mainSocket.emit("command", { to: [mac], scriptId });
  };

  const clearLogs = (mac) => {
    logs[mac] = [];
    setLogs({ ...logs });
  };

  const screenshot = (mac, doRefresh) => {
    mainSocket.emit("screenshot", { to: mac, from: mainSocket.id, doRefresh });
  };

  return {
    sockets: socketsRef.current,
    addConsole,
    removeConsole,
    removeAllConsoles,
    screenshot,
    execScript,
    mainExecScript,
    clearLogs,
    logs,
  };
}
