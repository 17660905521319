/*
    Check if conf.js is uniboservices.com
*/

import { Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import useFilterUrl from "../../../hooks/useFilterUrl";
import { generateFilter } from "../../../hooks/useDatagrid";
import { columns } from "../../BoxList";
import { Link as RouterLink } from "react-router-dom";
import Progress from "../../Progress";
import GraphqlError from "../../GraphqlError";

const BOXES = loader("../../../graphql/boxesCount.graphql");

export default function PciNestClientVersionDashboardPlugin() {
  const { generateFilterModel, compress, filterCompressed } = useFilterUrl();

  const filterModel = generateFilterModel(
    [
      ["supervision_wsStatus", "equals", "CONNECTED"],
      [
        "configuration_pciNestClientVersion",
        "not contains",
        window.__RUNTIME_CONFIG__.REACT_APP_PCI_CLIENT_VERSION,
      ],
      [
        "network_ipPublic",
        "!=",
        window.__RUNTIME_CONFIG__.REACT_APP_IP_UNIGUEST,
      ],
    ],
    "and",
  );

  if (!filterCompressed) {
    compress(filterModel);
  }

  const filter = generateFilter(filterModel, columns);

  const { loading, error, data } = useQuery(BOXES, {
    variables: {
      filter,
      sorting: { field: "configuration_hotel", direction: "ASC" },
      paging: { limit: 10000, offset: 0 },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 3600000,
  });

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <GraphqlError error={error} />;
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={`PCI CLIENT OUT OF DATE (!=${window.__RUNTIME_CONFIG__.REACT_APP_PCI_CLIENT_VERSION})`}
        sx={{ textAlign: "center" }}
      ></CardHeader>
      <CardContent>
        <Typography variant="h3" textAlign="center">
          <Link component={RouterLink} to={`/boxes?filter=${filterCompressed}`}>
            {data.boxes.totalCount}
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}
