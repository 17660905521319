import { Popover } from "@mui/material";
import React from "react";

export default function PopoverImage({
  src,
  width,
  height,
  popWidth,
  popHeight,
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <img
        width={width}
        height={height}
        style={{ objectFit: "contain" }}
        src={src}
        alt="screenshot"
        onClick={() => setOpen(true)}
      ></img>
      <Popover
        open={open}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <img
          width={popWidth}
          height={popHeight}
          style={{ objectFit: "contain" }}
          src={src}
          alt="screenshot"
        ></img>
      </Popover>
    </React.Fragment>
  );
}
