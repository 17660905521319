import React from "react";
import { Box, CssBaseline, Stack, Toolbar } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import MyAppBar from "./components/MyAppBar";
import MyDrawer from "./components/MyDrawer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BoxList from "./components/BoxList";
import LogList from "./components/LogList";
import ScriptList from "./components/ScriptList";
import ScriptEdit from "./components/ScriptEdit";
import graphqlClient from "./modules/GraphqlClient";
import useAuth from "./hooks/useAuth";
import Login from "./components/Login";
import ConsoleList from "./components/ConsoleList";
import { TokenContext } from "./contexts/tokenContext";
import useConsole from "./hooks/useConsole";
import ScreenshotList from "./components/ScreenshotList";
import UserList from "./components/UserList";
import UserEdit from "./components/UserEdit";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { BoxDetails } from "./components/BoxDetails";
import PlayerList from "./components/PlayerList";
import { Navigate } from "react-router-dom/dist";
import { PlayerDetails } from "./components/PlayerDetails";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

function AppGql() {
  const authHook = useAuth();
  const consoleHookDds = useConsole(authHook.token, true, "/");
  const consoleHookMelodjy = useConsole(authHook.token, true, "/melodjy");

  if (!authHook.token) {
    return <Login setToken={authHook.setToken} />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box display="flex" height="100vh">
          <CssBaseline />
          <BrowserRouter>
            <TokenContext.Provider value={authHook}>
              <MyAppBar />
              <MyDrawer user={authHook.user} />
              <Box flexGrow="1" height="100%">
                <Toolbar />
                <Routes>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route
                    exact
                    path="/boxes"
                    element={
                      <BoxList
                        consoleHook={consoleHookDds}
                        user={authHook.user}
                      />
                    }
                  />
                  <Route
                    path="/boxes/details"
                    element={<BoxDetails user={authHook.user} />}
                  />
                  <Route
                    exact
                    path="/players"
                    element={
                      <PlayerList
                        consoleHook={consoleHookMelodjy}
                        user={authHook.user}
                      />
                    }
                  />
                  <Route
                    path="/players/details"
                    element={<PlayerDetails user={authHook.user} />}
                  />
                  <Route
                    exact
                    path="/screenshots"
                    element={
                      <ScreenshotList
                        consoleHook={consoleHookDds}
                        user={authHook.user}
                      />
                    }
                  />
                  <Route
                    path="/screenshots/:src"
                    element={<ScreenshotList consoleHook={consoleHookDds}  user={authHook.user}/>}
                  />
                  <Route path="/logs" element={<LogList />} />
                  <Route exact path="/scripts" element={<ScriptList />} />
                  <Route path="/scripts/:id" element={<ScriptEdit />} />
                  <Route
                    path="/consoles/dds"
                    element={
                      <Stack direction="row">
                        <ConsoleList
                          consoleHook={consoleHookDds}
                          target="DDS"
                        />
                      </Stack>
                    }
                  />
                  <Route
                    path="/consoles/melodjy"
                    element={
                      <Stack direction="row">
                        <ConsoleList
                          consoleHook={consoleHookMelodjy}
                          target="MELODJY"
                        />
                      </Stack>
                    }
                  />
                  <Route exact path="/users" element={<UserList />} />
                  <Route path="/users/:id" element={<UserEdit />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route
                    path="*"
                    element={<Navigate to="/dashboard" replace={true} />}
                  />
                </Routes>
              </Box>
            </TokenContext.Provider>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default function App() {
  return (
    <ApolloProvider client={graphqlClient}>
      <AppGql />
    </ApolloProvider>
  );
}
