import React from "react";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Grid,
  Slider,
  TextField,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import SyncIcon from "@mui/icons-material/Sync";
import { WS_STATUS } from "../../constants/websocket";
import { useLocation, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import PopoverImage from "../PopoverImage";
import useFilterUrl from "../../hooks/useFilterUrl";
import DvrIcon from "@mui/icons-material/Dvr";
import { useNavigate } from "react-router-dom";
import Support from "../Roles/Support";

const BOXES = loader("../../graphql/boxes.graphql");
const HOTELS = loader("../../graphql/hotels.graphql");

function BoxLink({ items, children }) {
  const { compress, generateFilterModel, filterCompressed } = useFilterUrl();
  if (!filterCompressed) {
    compress(generateFilterModel(items, "and"));
  }
  return (
    <Link component={RouterLink} to={`/boxes?filter=${filterCompressed}`}>
      {children}
    </Link>
  );
}

function Screenshot({ box, xs, handleScreenshot, handleConsole, user }) {
  const theme = useTheme();

  return (
    <Grid item xs={xs} m={4}>
      <Card>
        <CardMedia
          sx={{
            height: xs * 100,
            backgroundSize: "contain",
            border: `1px solid
              ${
                box.supervision_wsStatus === WS_STATUS.CONNECTED
                  ? theme.palette.success.main
                  : theme.palette.warning.main
              }`,
          }}
        >
          <PopoverImage
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_SERVER}/screenshotDds/${box.mac}?${box.supervision_screenshotDate}`}
            width="100%"
            height="100%"
            popWidth="1366"
            popHeight="768"
          />
        </CardMedia>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <Box boxShadow={1} p={1}>
                <BoxLink
                  items={[
                    ["configuration_hotel", "equals", box.configuration_hotel],
                  ]}
                >
                  {box.configuration_hotel}
                </BoxLink>
              </Box>
            </Grid>
            <Grid item xs>
              <Box boxShadow={1} p={1}>
                {new Date(box.supervision_screenshotDate).toLocaleString(
                  "fr-FR",
                )}
              </Box>
            </Grid>
            <Grid item xs>
              <Box boxShadow={1} px={1}>
                {box.configuration_scenarioName}
              </Box>
            </Grid>
            <Grid item xs>
              <Box boxShadow={1} px={1}>
                <BoxLink items={[["mac", "equals", box.mac]]}>
                  {box.mac}
                </BoxLink>
              </Box>
            </Grid>
            <Grid item xs>
              <Box boxShadow={1} px={1}>
                {box.configuration_roomId}
              </Box>
            </Grid>
            <Grid item xs>
              <Box boxShadow={1} px={1}>
                {box.network_ip}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <Tooltip title="Refresh">
            <IconButton onClick={() => handleScreenshot()} size="small">
              <SyncIcon />
            </IconButton>
          </Tooltip>
          <Support user={user}>
            <Tooltip title="Console">
              <IconButton onClick={() => handleConsole()} size="small">
                <DvrIcon />
              </IconButton>
            </Tooltip>
          </Support>
        </CardActions>
      </Card>
    </Grid>
  );
}

function Hotels({ selected, handleChange }) {
  const { error, loading, data } = useQuery(HOTELS);

  if (error) {
    console.log(error);
    return <div>ERROR :-(</div>;
  }

  if (loading) {
    return <div>LOADING...</div>;
  }

  return (
    <Autocomplete
      options={data.hotels}
      value={selected}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Hôtel" variant="outlined" />
      )}
    />
  );
}

export default function ScreenshotList({ consoleHook, user }) {
  const [selected, setSelected] = React.useState();
  const [filter, setFilter] = React.useState({
    configuration_hotel: {
      eq: selected,
    },
  });
  const [xs, setXs] = React.useState(3);
  const { state } = useLocation();
  const { src } = useParams();

  const { loading, error, data } = useQuery(BOXES, {
    variables: {
      filter: src === "boxes" ? state.filter : filter,
      sorting:
        src === "boxes"
          ? state.sorting
          : { field: "configuration_roomId", direction: "ASC" },
      paging:
        src === "boxes"
          ? state.paging
          : { limit: selected ? 500 : 0, offset: 0 },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: window.__RUNTIME_CONFIG__.REACT_APP_POLL_INTERVAL,
  });

  const handleChange = (event, hotel) => {
    setSelected(hotel);
    if (hotel) {
      setFilter({
        configuration_hotel: {
          eq: hotel,
        },
      });
    } else {
      setFilter();
    }
  };

  const navigate = useNavigate();

  if (error) {
    return <div>ERROR :-(</div>;
  }

  if (loading) {
    return <div>LOADING...</div>;
  }

  return (
    <Box pt={1}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Hotels selected={selected} handleChange={handleChange} />
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" alignItems="center" height="100%">
            <ZoomOutIcon />
            <Box width="100%" px={1}>
              <Slider
                value={xs}
                min={1}
                max={12}
                onChange={(event, newValue) => setXs(newValue)}
              />
            </Box>
            <ZoomInIcon />
          </Box>
        </Grid>
        <Grid container spacing={3}>
          {data?.boxes?.nodes &&
            data.boxes.nodes.map((box) => (
              <Screenshot
                key={box.mac}
                box={box}
                xs={xs}
                handleScreenshot={() => consoleHook.screenshot([box.mac])}
                handleConsole={() => {
                  consoleHook.addConsole(box.mac);
                  navigate(`/consoles/dds`);
                }}
                user={user}
              />
            ))}
        </Grid>
      </Grid>
    </Box>
  );
}
