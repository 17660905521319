import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export default function Progress() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <CircularProgress />
    </Box>
  );
}
