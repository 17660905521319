import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastForwardIcon from "@mui/icons-material/FastForward";
import CloseIcon from "@mui/icons-material/Close";
import ReactJson from "react-json-view";
import { WS_STATUS } from "../../constants/websocket";
import { useTheme } from "@mui/material/styles";

const BOX = loader("../../graphql/box.graphql");
const PLAYER = loader("../../graphql/player.graphql");

function TabHeaderDds({ mac, ...other }) {
  const theme = useTheme();
  const { loading, error, data } = useQuery(BOX, {
    variables: { id: mac },
    pollInterval: window.__RUNTIME_CONFIG__.REACT_APP_POLL_INTERVAL,
  });

  if (loading) {
    return <div>LOADING...</div>;
  }

  if (error) {
    console.error(error);
    return <div>ERROR :-(</div>;
  }

  return (
    <Tab
      key={mac}
      label={`${data?.box?.configuration_hotel} ${data?.box?.configuration_roomId}`}
      style={{
        color:
          data?.box?.supervision_wsStatus === WS_STATUS.CONNECTED
            ? theme.palette.success.main
            : theme.palette.warning.main,
      }}
      {...other}
    />
  );
}

function TabHeaderMelodjy({ mac, ...other }) {
  const theme = useTheme();
  const { loading, error, data } = useQuery(PLAYER, {
    variables: { id: mac },
    pollInterval: window.__RUNTIME_CONFIG__.REACT_APP_POLL_INTERVAL,
  });

  if (loading) {
    return <div>LOADING...</div>;
  }

  if (error) {
    console.error(error);
    return <div>ERROR :-(</div>;
  }

  return (
    <Tab
      key={mac}
      label={`${data?.player?.playerConfig.siteCode} ${data?.player?.mac}`}
      style={{
        color:
          data?.player?.supervision_wsStatus === WS_STATUS.CONNECTED
            ? theme.palette.success.main
            : theme.palette.warning.main,
      }}
      {...other}
    />
  );
}

function TabPanel({ logs, value, index, clearLogs, removeConsole }) {
  const [collapsed, setCollapsed] = React.useState(false);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box>
          <ButtonGroup color="primary">
            <Button onClick={() => clearLogs()}>Clear</Button>
            <Button onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? "Uncollapsed" : "Collapsed"}
            </Button>
            <Button onClick={() => removeConsole()}>Close</Button>
          </ButtonGroup>
          <ul>
            {logs &&
              logs.map((log, index) => (
                <li key={index}>
                  {log.date.toLocaleTimeString()} :
                  <Box color={log.type === "error" ? "red" : undefined}>
                    <ReactJson
                      src={log.arguments}
                      collapsed={collapsed}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                  </Box>
                </li>
              ))}
          </ul>
        </Box>
      )}
    </div>
  );
}

const SCRIPTS = loader("../../graphql/scripts.graphql");

export default function ConsoleList({ consoleHook, target }) {
  const [script, setScript] = React.useState("");
  const [scripts, setScripts] = React.useState([]);

  const paging = { limit: 50, offset: 0 };
  const filter = { targets: { name: { eq: target } } };

  const { loading, error } = useQuery(SCRIPTS, {
    variables: {
      paging,
      filter,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setScripts(data.scripts.nodes);
    },
  });

  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (error) {
    console.error(error);
    return <div>ERROR :-(</div>;
  }

  if (loading) {
    return <div>LOADING...</div>;
  }

  const handleChangeScript = (event) => {
    setScript(event.target.value);
  };

  const handleExecScript = (event) => {
    consoleHook.execScript(consoleHook.sockets[tab], script);
  };

  const handleExecScriptAll = (event) => {
    consoleHook.sockets.forEach((socket) =>
      consoleHook.execScript(socket, script),
    );
  };

  const handleCloseAll = () => {
    consoleHook.removeAllConsoles();
  };

  return (
    <Stack direction="column">
      <Typography m={2} variant="h6">
        {target}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Box minWidth={500} maxWidth={500}>
          <FormControl
            sx={{
              margin: 1,
              minWidth: 120,
            }}
            fullWidth={true}
          >
            <InputLabel>Script</InputLabel>
            <Select
              value={script}
              onChange={handleChangeScript}
              autoWidth
              label="Script"
            >
              {scripts.map((script) => (
                <MenuItem key={script.id} value={script.id}>
                  <Box maxWidth={500}>
                    <Typography>{script.name}</Typography>
                    {script.description && (
                      <Tooltip title={script.description} placement="right">
                        <Typography
                          variant="subtitle2"
                          ml={2}
                          sx={{ opacity: 0.5 }}
                        >
                          {script.description}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" alignItems="center">
          <ButtonGroup color="primary">
            <Tooltip title="Exec">
              <IconButton onClick={() => handleExecScript()} size="large">
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Exec multiple">
              <IconButton onClick={() => handleExecScriptAll()} size="large">
                <FastForwardIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close all">
              <IconButton onClick={() => handleCloseAll()} size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Stack>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "background.paper",
          display: "flex",
          height: "80%",
        }}
      >
        <Tabs
          sx={{
            minWidth: "250px",
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          orientation="vertical"
          variant="scrollable"
          value={tab}
          onChange={handleChange}
        >
          {target === "DDS" &&
            consoleHook.sockets.map((socket) => (
              <TabHeaderDds key={socket.mac} mac={socket.mac} />
            ))}
          {target === "MELODJY" &&
            consoleHook.sockets.map((socket) => (
              <TabHeaderMelodjy key={socket.mac} mac={socket.mac} />
            ))}
        </Tabs>
        {consoleHook.sockets.map((socket, index) => {
          const logs = consoleHook.logs[socket.mac];
          return (
            <TabPanel
              key={socket.mac}
              value={tab}
              index={index}
              logs={logs}
              clearLogs={() => consoleHook.clearLogs(socket.mac)}
              removeConsole={() => consoleHook.removeConsole(socket.mac)}
            ></TabPanel>
          );
        })}
      </Box>
    </Stack>
  );
}
