import React from "react";
var codec = require("json-url")("lzw");

export default function useFilterUrl() {
  const [filterDecompressed, setFilterDecompressed] = React.useState();
  const [filterCompressed, setFilterCompressed] = React.useState();

  function generateFilterModel(items, logicOperator) {
    const filter = {
      items: items.map((item, index) => {
        return {
          id: index,
          field: item[0],
          operator: item[1],
          value: item[2],
        };
      }),
      logicOperator,
    };
    return filter;
  }

  function compress(data, callback) {
    codec.compress(data).then((result) => {
      setFilterDecompressed(data);
      setFilterCompressed(result);
      callback && callback(result);
    });
  }

  function decompress(data, callback) {
    codec.decompress(data).then((result) => {
      setFilterCompressed(data);
      setFilterDecompressed(result);
      callback && callback(result);
    });
  }

  return {
    compress,
    decompress,
    filterCompressed,
    filterDecompressed,
    generateFilterModel,
  };
}
