import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";

function getMac(params) {
  return params.row.box?.mac;
}

function getHotel(params) {
  return params.row.box?.configuration_hotel;
}

function getRoomId(params) {
  return params.row.box?.configuration_roomId;
}

function getDateCreated(params) {
  return new Date(params.row?.date_created);
}

const columns = [
  {
    field: "box.mac",
    width: 150,
    valueGetter: getMac,
    sortable: false,
  },
  {
    field: "box.configuration_hotel",
    headerName: "box.hotel",
    width: 150,
    valueGetter: getHotel,
    sortable: false,
  },
  {
    field: "box.configuration_roomId",
    headerName: "box.roomId",
    width: 150,
    valueGetter: getRoomId,
    sortable: false,
    type: "number",
  },
  { field: "date_created", width: 250, type: "dateTime", valueGetter: getDateCreated },
  { field: "type", width: 150 },
  { field: "value", flex: 1 },
];

const QUERY = loader("../../graphql/logs.graphql");

export default function LogList() {
  const context = useDatagrid({
    id: "LogList",
    columns,
    defaultSort: [{ field: "date_created", sort: "desc" }],
  });

  return (
    <div style={{ display: "flex", height: "90%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid columns={columns} context={context} query={QUERY} />
      </div>
    </div>
  );
}
