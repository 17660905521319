import React from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { loader } from "graphql.macro";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

const QUERY = loader("../../graphql/user.graphql");
const CREATE = loader("../../graphql/createOneUser.graphql");
const UPDATE = loader("../../graphql/updateOneUser.graphql");

export default function UserEdit() {
  const { id } = useParams();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [roles, setRoles] = React.useState(["USER"]);

  let navigate = useNavigate();

  useQuery(QUERY, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      setUsername(data?.user?.username || "");
      setRoles(data?.user?.roles || ["USER"]);
    },
  });

  const [createOneUser] = useMutation(CREATE, {
    onCompleted: () => navigate(-1),
  });

  const [updateOneUser] = useMutation(UPDATE, {
    onCompleted: () => navigate(-1),
  });

  const handleSave = () => {
    if (id === "create") {
      createOneUser({
        variables: {
          input: {
            user: {
              username,
              password,
            },
          },
        },
      });
    } else {
      let update = { username, roles };
      if (password !== "") {
        update.password = password;
      }
      updateOneUser({
        variables: {
          input: {
            id,
            update,
          },
        },
      });
    }
  };

  return (
    <Box m={1}>
      <Stack width="20%" spacing={2}>
        <TextField
          id="outlined-basic"
          required
          label="Username"
          variant="outlined"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField
          id="outlined-basic"
          required
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel>Rôles</InputLabel>
          <Select
            multiple
            value={roles}
            label="Rôles"
            onChange={({ target: { value } }) => {
              setRoles(typeof value === "string" ? value.split(",") : value);
            }}
          >
            <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
            <MenuItem value={"SUPERUSER"}>SUPERUSER</MenuItem>
            <MenuItem value={"USER"}>USER</MenuItem>
            <MenuItem value={"PLAYER"}>PLAYER</MenuItem>
            <MenuItem value={"CONSOLE"}>CONSOLE</MenuItem>
            <MenuItem value={"SUPPORT"}>SUPPORT</MenuItem>
          </Select>
        </FormControl>
        <ButtonGroup color="primary">
          <Button onClick={() => handleSave()}>Enregistrer</Button>
          <Button onClick={() => navigate(-1)}>Annuler</Button>
        </ButtonGroup>
      </Stack>
    </Box>
  );
}
