import React from "react";

export const tokenContext = {
  token: null,
  setToken: () => {},
  removeToken: () => {},
};

export const TokenContext = React.createContext(
  tokenContext // valeur par défaut
);
