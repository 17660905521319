import { List, ListItem, ListItemText, Switch } from "@mui/material";
import React from "react";

export default function Settings() {
  const [
    disableConfirmationDialog,
    setDisableConfirmationDialog,
  ] = React.useState(
    window.localStorage.getItem("disableConfirmationDialog") === "true"
  );

  const onChangeDisableConfirmationDialog = (event) => {
    setDisableConfirmationDialog(event.target.checked);
    window.localStorage.setItem(
      "disableConfirmationDialog",
      event.target.checked
    );
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem>
        <ListItemText primary="disableConfirmationDialog" />
        <Switch
          edge="end"
          onChange={onChangeDisableConfirmationDialog}
          checked={disableConfirmationDialog}
        />
      </ListItem>
    </List>
  );
}
