import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";

const LOGIN = loader("../../graphql/login.graphql");

export default function Login({ setToken }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [loginMutation, { error }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      setToken(data.login);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginMutation({
      variables: { input: { username: login, password } },
    });
  };

  return (
    <Container height="100%">
      <form onSubmit={handleSubmit}>
        <Box
          width="100%"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Card>
            <CardContent>
              <Typography variant="h4">Authentification</Typography>
            </CardContent>
            <List>
              <ListItem>
                <TextField
                  error={!!error}
                  label="Nom d'utilisateur"
                  fullWidth={true}
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </ListItem>
              <ListItem>
                <TextField
                  error={!!error}
                  label="Mot de passe"
                  fullWidth={true}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </ListItem>
            </List>
            <CardActions>
              <Button variant="contained" color="primary" type="submit">
                Connexion
              </Button>
            </CardActions>
          </Card>
        </Box>
      </form>
    </Container>
  );
}
